<template>
  <div class="UploadItem">
    <v-card tile>
      <v-card-text>
        <v-row dense>
          <v-col cols="2">
            <h4>
              <b>{{ item.id }}</b>
            </h4>
            <h4>{{ fullName }}</h4>
            {{ item.user.email }} <br />
            {{ dateFormatted }}
          </v-col>
          <v-col cols="8" class="d-flex flex-wrap">
            <div
              class="d-flex mr-4 mb-4"
              v-for="upload in item.uploadImages"
              :key="upload.id"
            >
              <div class="d-flex align-center mr-2 thumbs">
                <v-img
                  width="60"
                  height="60"
                  :src="buildImage(upload.fridgeDisplayType.sampleImage)"
                  @click="enlarge(upload.fridgeDisplayType.sampleImage)"
                  class="thumb"
                ></v-img>
                <v-img
                  width="60"
                  height="60"
                  :src="buildImage(upload)"
                  @click="enlarge(upload)"
                  class="thumb"
                ></v-img>
              </div>
              <div
                v-if="upload.status === 'PENDING'"
                class="d-flex align-center thumb-ui"
              >
                <v-btn
                  class="pr-0 pl-0"
                  color="black"
                  min-width="36px"
                  dark
                  background-color="secondary"
                  @click="accept(upload)"
                  ><v-icon>mdi-check</v-icon></v-btn
                >
                <v-btn
                  class="pr-0 pl-0"
                  color="primary"
                  min-width="36px"
                  dark
                  @click="reject(upload)"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </div>
              <div v-else>
                {{ formatUploadStatus(upload.status) }} <br />
                <v-btn
                  small
                  @click="displayRejectCause(upload.rejectCause)"
                  v-if="upload.status === 'REJECTED'"
                  >OKA</v-btn
                >
              </div>
            </div>
          </v-col>
          <v-col cols="2">
            {{ formatStatus(item.status) }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog max-width="600" v-model="isPreview">
      <v-img v-if="isPreview" :src="activePreview"></v-img>
    </v-dialog>
    <v-dialog v-model="isReject" max-width="600">
      <v-card>
        <v-card-title>Elutasítás oka</v-card-title>
        <v-card-text>
          <v-textarea outlined v-model="activeRejectionMessage"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="this.activeRejectionMessage.length < 2"
            @click="finalize()"
            color="x-large secondary"
            >Mentés</v-btn
          >
          <v-btn @click="isReject = false" color="x-large outlined"
            >Mégsem</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isRejectionCause" max-width="600">
      <v-card>
        <v-card-title>Elutasítás oka</v-card-title>
        <v-card-text>
          {{ this.activeRejectionCause }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="isRejectionCause = false" color="x-large outlined"
            >Mégsem</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import messageBus from "../messageBus.js";
import { API_BASE, IMAGE_BASE } from "../util/constants";
import {
  getMultipartHeader,
  getLoggedInHeader,
  toQueryString,
} from "../util/functions.js";
import UploadItem from "../components/UploadItem.vue";
import moment from "moment";
export default {
  props: ["item"],
  data() {
    return {
      isPreview: false,
      activePreview: null,
      activeItem: null,
      activeRejectionMessage: "",
      isReject: false,
      isRejectionCause: false,
      activeRejectionCause: null,
    };
  },
  methods: {
    buildImage(image) {
      let name = image.filename;
      let folder = image.dirPath.substring(1);
      return `${IMAGE_BASE}${folder}${name}`;
    },
    enlarge(_image) {
      const image = this.buildImage(_image);
      this.isPreview = true;
      this.activePreview = image;
    },
    formatStatus(status) {
      return status === "PENDING" ? "Függőben" : "Visszajelezve";
    },
    formatUploadStatus(status) {
      return status === "ACCEPTED" ? "Elfogadva" : "Elutasítva";
    },
    displayRejectCause(cause) {
      this.activeRejectionCause = cause;
      this.isRejectionCause = true;
    },
    async accept(item) {
      const requestOptions = {
        method: "PUT",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        redirect: "follow",
      };
      try {
        let response = await fetch(
          `${API_BASE}admin/upload-images/${item.id}/accept`,
          requestOptions
        );

        if (response.ok) {
          const jsonResponse = await response.json();
          eventBus.$emit("REFRESH_UPLOADS");
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async finalize() {
      const requestOptions = {
        method: "PUT",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        redirect: "follow",
      };
      const str = toQueryString({
        rejectCause: this.activeRejectionMessage,
      });
      try {
        let response = await fetch(
          `${API_BASE}admin/upload-images/${this.activeItem.id}/reject?${str}`,
          requestOptions
        );

        if (response.ok) {
          const jsonResponse = await response.json();
          this.isReject = false;
          this.activeRejectionMessage = "";
          this.activeItem = null;
          console.log(jsonResponse);
          eventBus.$emit("REFRESH_UPLOADS");
        } else {
          messageBus.$emit("onNewMessage", {
            type: "error",
            label: this.$t("message.error"),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    reject(item) {
      this.isReject = true;
      this.activeItem = item;
    },
  },
  computed: {
    fullName() {
      return this.item.user.lastName + " " + this.item.user.firstName;
    },
    dateFormatted() {
      return moment.unix(this.item.createdAt).format("YYYY.MM.DD");
    },
  },
};
</script>
<style scoped>
.thumb {
  object-fit: cover !important;
  border: 2px solid #ccc;
  overflow: hidden;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}
</style>
