<template>
  <div class="Uploads">
    <v-container>
      <v-card>
        <v-card-text>
          <div class="bar">
            <div class="bar">
              <TextSearch></TextSearch>
            </div>
          </div>
          <div class="d-flex align-center">
            <v-checkbox
              class="mr-2"
              v-model="isPendingFilter"
              label="Függőben"
              @change="handleStatusFilters()"
            ></v-checkbox>
            <v-checkbox
              v-model="isFeedbackFilter"
              label="Visszajelezve"
              @change="handleStatusFilters()"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <small class="mr-2">Feltöltés időtartama:</small>
            <v-menu
              v-model="isFrom"
              :close-on-content-click="true"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="fromDateFormatted"
                  label="-tól"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4"
                  outlined
                  append-icon="mdi-calendar"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fromDate"></v-date-picker>
            </v-menu>
            <v-menu
              v-model="isTo"
              :close-on-content-click="true"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="toDateFormatted"
                  label="-ig"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4"
                  outlined
                  append-icon="mdi-calendar"
                ></v-text-field>
              </template>
              <v-date-picker v-model="toDate"></v-date-picker>
            </v-menu>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <UploadItem
        v-for="item in items"
        :item="item"
        :key="item.id"
      ></UploadItem>
    </v-container>
    <v-container class="mb-8">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        total-visible="9"
        class="pagination"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import { API_BASE, IMAGE_BASE } from "../util/constants.js";
import {
  getMultipartHeader,
  getLoggedInHeader,
  toQueryString,
} from "../util/functions.js";
import UploadItem from "../components/UploadItem.vue";
import TextSearch from "../components/TextSearch.vue";
import moment from "moment";

export default {
  name: "Uploads",
  components: { UploadItem, TextSearch },
  created() {},
  mounted() {
    this.getList();
    eventBus.$on("REFRESH_UPLOADS", () => {
      this.getList();
    });
    eventBus.$on("DO_SEARCH", (keyword) => {
      this.filter.keyword = keyword;
      this.currentPage = 1;
      this.getList();
    });
  },
  data() {
    return {
      filter: {
        endDate: null,
        keyword: null,
        startDate: null,
        uploadStatuses: ["PENDING", "FEEDBACK"],
      },
      isFrom: false,
      isTo: false,
      fromDate: null,
      toDate: null,
      isPendingFilter: true,
      isFeedbackFilter: true,
      currentPage: 1,
      items: [],
      totalPages: 0,
      totalItems: 0,
    };
  },
  methods: {
    handleStatusFilters() {
      this.filter.uploadStatuses = [];
      if (this.isPendingFilter) this.filter.uploadStatuses.push("PENDING");
      if (this.isFeedbackFilter) this.filter.uploadStatuses.push("FEEDBACK");
      this.currentPage = 1;
      this.getList();
    },
    handleDateFilters(isFrom) {
      this.filter.uploadStatuses = [];
      if (this.isPendingFilter) this.filter.uploadStatuses.push("PENDING");
      if (this.isFeedbackFilter) this.filter.uploadStatuses.push("FEEDBACK");
      this.currentPage = 1;
      this.getList();
    },
    updateFilter() {},
    async getList() {
      this.isFilter = false;
      // this.defaultFilter.uploadStatuses = this.statusFilterString;
      const requestOptions = {
        method: "PATCH",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        body: JSON.stringify(this.filter),
        redirect: "follow",
      };
      try {
        let response = await fetch(
          `${API_BASE}admin/uploads/page/${this.currentPage}/filtered`,
          requestOptions
        );

        if (response.ok) {
          const jsonResponse = await response.json();
          this.items = jsonResponse.data;
          this.totalItems = jsonResponse.totalItems;
          this.totalPages = jsonResponse.totalPages;
          this.currentPage = jsonResponse.currentPage;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
  },
  computed: {
    fromDateFormatted() {
      return this.fromDate ? moment(this.fromDate).format("YYYY.MM.DD") : "";
    },
    toDateFormatted() {
      return this.toDate ? moment(this.toDate).format("YYYY.MM.DD") : "";
    },
  },
  watch: {
    currentPage: function () {
      this.getList();
    },
    fromDate(val) {
      if (val) {
        this.filter.startDate = moment(val).unix();
      } else {
        this.filter.startDate = null;
      }
      this.currentPage = 1;
      this.getList();
    },
    toDate(val) {
      if (val) {
        this.filter.endDate = moment(val).unix();
      } else {
        this.filter.endDate = null;
      }
      this.currentPage = 1;
      this.getList();
    },
  },
};
</script>
