<template>
  <div class="TextSearch">
    <v-text-field
      :label="$t('app.search')"
      v-model="term"
      tile
      @keypress.enter="action()"
      color="secondary"
    >
      <template v-slot:append>
        <v-btn x-large color="secondary" class="ma-0" @click="action()">
          {{ $t("app.search") }}
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
export default {
  name: "TextSearch",
  data() {
    return {
      term: "",
    };
  },
  methods: {
    action() {
      eventBus.$emit("DO_SEARCH", this.term);
    },
  },
  computed: {
    isReady() {
      return true;
    },
  },
};
</script>
<style>
.TextSearch {
  /*min-width: 100%;*/
}
</style>
